import React from 'react';

import '../styles/main.css';
import mainImage from "../images/icon.png"
import appStore from "../images/appstore.svg"
import Container from "react-bootstrap/Container";

class Main extends React.Component {
    mainBackground = "linear-gradient(to right, #b8cbb8 0%, #b8cbb8 0%, #b465da 0%, #cf6cc9 33%, #ee609c 66%, #ee609c 100%)";

    render() {
        return (
            <Container fluid style={{padding: 0, margin: 0}} className="main">
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <Container>
                        <div className="row text-center">
                            <div className="col pb-3 text-light">
                                <h1>Vuzy Live</h1>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col">
                                <img src={mainImage} alt="Vuzy Live" style={{borderRadius: '25px', maxWidth:'384px',maxHeight:'384px'}}></img>
                            </div>
                        </div>
                        <div className="row text-center">
                            <div className="col pt-3">
                                <div><a href="https://itunes.apple.com/app/1673081536" target="_blank" rel="noopener noreferrer"><img src={appStore} alt="Vuzy Live" width={200+"px"}/></a></div>
                            </div>
                        </div>
                    </Container>
                </div>
                <footer className="mt-2 p-3 sticky-bottom">
                    <div className="container text-center">
                        <small>2023 &copy; vuzylive.com | </small>
                        <small><a className="text-decoration-none text-dark" href="/terms">Terms & Conditions | </a></small>
                        <small><a className="text-decoration-none text-dark" href="/privacy">Privacy Policy</a></small>
                    </div>
                </footer>
            </Container>
        )
    }
}

export default Main;