import React from 'react';

import '../styles/main.css';

import Container from "react-bootstrap/Container";

class Terms extends React.Component {
    render() {
        return (
            <Container fluid>
                <ol>
                    <li>Introduction
                        <ol>
                            <li>This document, which may also be referred to as "User Agreement" or "Agreement" throughout, is a legally binding agreement between individuals, businesses, and
                                organizations (Users) and Vuzy Live (https://vuzylive.com/), which may also be referred to as the "Website" or "Web-site" hereafter and the Vuzy Live mobile application, which may
                                also
                                be referred to as the "App" or "Application" hereafter, both of which together may be referred to as "Services" throughout this agreement).
                            </li>
                            <li>The terms "us", "We", and "ours" may also be used to refer to Vuzy Live throughout, while the terms "you", "your", "User", and "Visitor", may be used to refer to all users
                                of
                                Vuzy Live Services. The terms "Content" and "Contents" are used to refer to any materials made available via Vuzy Live.
                            </li>
                            <li>To access and use all services provided by Vuzy Live you confirm that you are at least 18 years of age.</li>
                            <li>By using our Services you agree that you comply with the terms of this User Agreement. You also confirm that you agree with our Privacy Policy.</li>
                        </ol>
                    </li>
                    <li>Gaining access to and using Services on Vuzy Live
                        <ol>
                            <li>Registration and User Accounts:
                                <ol>
                                    <li>Access to your User Account and use of our Services is gained through a login username and password or a social network account (both of which may be referred
                                        to as
                                        "Credentials" or "User Credentials" hereafter) which is used to authenticate you as a genuine User for use of our Services. All Users bare full responsibility
                                        for
                                        the security of their User Credentials.
                                    </li>
                                    <li>Without clear proof of fraud, any and all actions performed using your User Credentials are assumed and considered to have been performed by you.</li>
                                    <li>The processing and storage of all personal information provided to Vuzy Live by Users of our Services is undertaken by the terms described in our Privacy Policy.
                                    </li>
                                </ol>
                            </li>
                            <li>All issues and/or costs associated with Internet connections and/or mobile data connection services are the responsibility of the individual User. Vuzy Live advises all
                                Users
                                to clarify the costs of any Internet or data connections before using our services if you are unsure.
                            </li>
                            <li>Vuzy Live reserves the right to stop (temporarily or for an indefinite period of time) functioning as a Service or to perform maintenance on one or several of our
                                Services. We
                                also reserve the right to alternate one or several of our Services:
                                <ol>
                                    <li>For technical reasons such as issues with our servers, hardware or software malfunctions, hacker attacks, etc.</li>
                                    <li>To perform updates to our hardware or software for the benefit of our Services and/or user experience.</li>
                                    <li>In any case for legitimate reasons such as objections regarding privacy or legitimate objections regarding the content or maintenance of our Services.</li>
                                    <li>If we decide a Service no longer has commercial reason for continuing operation.s</li>
                                    <li>Should any changes of services provided by us occurs.</li>
                                </ol>
                            </li>
                            <li>Vuzy Live does not specifically exclude cases when our Services may be unavailable (temporarily or for an infinite period of time) due to technical or maintenance issues.
                                Our
                                Service is not immune from viruses, hacker attacks, malfunctions of hardware and software, and other unexpected issues that are out of our control.
                                <ol>
                                    <li>We do not take responsibility for any issues or harm, including material damage, experienced by Users of our Services due to technical reasons.</li>
                                    <li>We do not take responsibility for any issues or harm experienced by Users of our Services which is caused by Internet or data malfunctions.</li>
                                    <li>We promise to take any feasible actions to resume correct functionality of our Services in the case of any technical or maintenance issues.</li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>User contents
                        <ol>
                            <li>You are responsible for owning all copyrights to materials you provide and for their Contents.</li>
                            <li>By providing Us with your Contents, you automatically grant Us with a non-exclusive, free, and termless global license to use these Contents in any way. This includes
                                any
                                changing of the Contents and the use of said Contents for commercial purposes.
                            </li>
                            <li>The copyright for all Contents remain in the possession of the User who has provided them.</li>
                            <li>The copyright to all other Contents, including graphics, UI design, etc., found in our Services belongs to Us.</li>
                            <li>You agree not to use any Contents found in our Service that you do not own the copyright to, including but not limited to Contents provided by Vuzy Live and Contents
                                provided
                                by other Users. You also agree not to transfer any Contents for use by third parties.
                            </li>
                            <li>You agree not to provide any Contents that violate our Terms and Guidelines of Service, the rights of other Users, or may be viewed as obscene or offensive to others.
                            </li>
                            <li>You agree not to promote, advertise, or offer any type of commercial services when using our Services.</li>
                            <li>We reserve the right to delete, without prior notice or warning to the User, any Contents provided by Users that violate our Terms and Guidelines, rules, or third
                                parties’
                                copyrights.
                            </li>
                            <li>We agree to comply with our Privacy Policy at all times.</li>
                        </ol>
                    </li>
                    <li>Paid services
                        <p>We offer some paid services to our Users, on both our Website and Mobile Application.</p>
                        <ol>
                            <li>By downloading and installing our Application onto your device, you automatically activate a paid subscription to use our Services. Your subscription renews
                                automatically
                                unless you have turned off the auto-renew option at least 24 hours prior to the end of your current contracted period. Accounts are charged for the next subscription
                                period
                                in the 24 hours before the current contract ends at the same price as the current contract. If you turn off auto-renew in the 24 hours before your current contract
                                ends,
                                the subscription will not be cancelled until the end of the next period.
                            </li>
                            <li>Subscriptions and paid services may not be transferred to third parties.</li>
                            <li>We may offer additional paid features in our Services, such as the ability to remove a ban before the expiry of the ban period. These paid features may be purchased via
                                in-app payments on our Mobile Application or via a range of proposed payment methods on our Website. It is the responsibility of all Users to check the payment terms
                                for
                                payments made in-app on our Mobile Application.
                            </li>
                            <li>All payments for paid services on both our Mobile Application are final and not subject to refunds.</li>
                            <li>Pricing for paid services is determined by a number of factors including, but not limited to, promotions, loyalty bonuses, and other discounts applied to age groups and
                                activity on our Services.
                            </li>
                        </ol>
                    </li>
                    <li>Termination of access
                        <ol>
                            <li>You reserve the right to delete your profile on our Services, uninstall the Mobile Application, or cancel a subscription at any time.</li>
                            <li>Any User who violates the Chat Rules while using our Services, the terms of this User Agreement, or provides or distributes Contents as lined out in p.3 of this
                                Agreement,
                                may have access to our Services limited without any notice or obligation (financial or other) including:
                                <ol>
                                    <li>The suspension or cancellation of a User’s registration (if the named exists) and/or a limit or restriction placed on a User’s access to our Services.</li>
                                    <li>The right for Us to use any software, technical, legal, and other means to ensure these Terms are met (including, without any limitations, blocking certain IP
                                        addresses).
                                    </li>
                                </ol>
                            </li>
                            <li>We attempt to notify all Users about any such restrictions, however, are not under obligation to do so.</li>
                            <li>In the event that a User has their access to Services limited or restricted due to a violation of the Chat Rules, the subscription and renewal of subscription remains
                                valid
                                and in place until such time as a User cancels the subscription.
                            </li>
                            <li>Any and all provisions related to or regarding limitation of liability, disclaimers, and indemnification survive any termination of this Agreement or any termination of
                                your use of or subscription to our Services.
                            </li>
                        </ol>
                    </li>
                    <li>Abuses and complaints
                        <ol>
                            <li>Any incidents of abuse, violations of the Chat Rules, and/or improper use of your copyright by other Users may be reported via email addresses, contact forms, and other
                                methods of contact as presented in our Services.
                            </li>
                            <li>Users who attempt to resolve any disputes with other Users of our Services by violating the Chat Rules, terms of this Agreement or Privacy Policy, and/or by taking
                                illegal
                                actions such as inappropriate remarks to other Users are liable to have their access to our Services limited on a temporary or permanent basis, as per p.6 in this
                                Agreement.
                            </li>
                        </ol>
                    </li>
                    <li>Limitation of liability
                        <ol>
                            <li>We are not responsible for any actions taken by our Users while on our Services or for any of the Contents provided by them.</li>
                            <li>We do not accept any liability resulting from the use of our Services, including liability resulting from lawsuits, penalties, claims, damages, obligations, expenses of
                                any
                                nature and type including direct, indirect, occasional, penalties, and/or losses caused by negligence, service interruption, loss of data, hardware damage due to virus
                                attacks, loss of income, loss of opportunity, waste of office time, breaching of a contract, third party claims, or any other losses that may have been foreseen as
                                those
                                which may be caused due to or during the use of our Services. This liability limitation covers, but is not limited to, transmission of any software or viruses that may
                                harm
                                your hardware, cause malfunctions of mechanical or electrical hardware or of communication lines, other connection issues (such as impossibility of Internet access),
                                unauthorized access, stealing, injury, property damage, operator errors, strikes or force majeure, including, but not limited to liability for loss of income, profits
                                or
                                contracts loss, loss of business, loss of anticipated savings, loss of goodwill, loss of data, loss of office time, and any other loss or damage of any kind.
                            </li>
                            <li>You agree to protect Vuzy Live and its employees from any and all claims of third parties and any losses and costs incurred from claims resulting from your personal
                                actions
                                while using our Services or due to Contents provided by you.
                            </li>
                        </ol>
                    </li>
                    <li>Amendments
                        <p>We reserve the right, without any prior notification to you, to amend or change the Chat Rules and Guidelines, Privacy Policy, and this Agreement.</p>
                        <ol>
                            <li>The most recent and up to date copies of the above documents are published on our Website.</li>
                            <li>Any amendments become active and valid from the moment the amended text is updated on the respective page on our Website or from a starting date indicated on our
                                Website.
                            </li>
                            <li>In some instances we may notify Users of amendments to documents, however, it is the responsibility of the User to regularly check the respective pages for updates.
                            </li>
                            <li>Should you disagree with any terms outlined in this Agreement, you should stop using our Services with immediate effect. Continuing to use our Services after any
                                amendments
                                come into effect indicate you agree with all changes and the amended terms of this Agreement.
                            </li>
                        </ol>
                    </li>
                </ol>
            </Container>
        )
    }
}

export default Terms;
