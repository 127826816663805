import logo from './logo.svg';
import './App.css';
import {Component} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Terms from "./components/terms";
import Privacy from "./components/privacy";
import Main from "./components/main";

class App extends Component {
  render() {
    return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/terms" element={<Terms/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
          </Routes>
        </BrowserRouter>
    )
  }
}

export default App;
