import React from 'react';

import '../styles/main.css';

import Container from "react-bootstrap/Container";

class Privacy extends React.Component {
    render() {
        return (
            <Container fluid>
                <ol>
                    <li>Introduction
                        <ol>
                            <li>This document, "Privacy Policy", describes how Vuzy Live collects, processes, and stores
                                the personal data of users on Vuzy Live (https://vuzylive.com/), which may also be
                                referred
                                to
                                as "Website" or "Web-Site" hereafter and the Vuzy Live mobile application, which may
                                also be referred to as the "App" or "Application" hereafter, both of which together
                                may be
                                referred to as "Services" throughout this Privacy Policy).
                            </li>
                            <li>The terms "us", "We", and "ours" may also be used to refer to Vuzy Live throughout,
                                while the terms "you", "your", "User", and "Visitor", may be used to refer to all users
                                of
                                Vuzy Live Services.
                            </li>
                            <li>The term Personal Data in this document understands identifying and non-identifying data
                                such as full name, address, phone number, and/or email address. These may be
                                referred to as "User Personal Data", "Personal Data", or "Data" throughout.
                            </li>
                        </ol>
                    </li>
                    <li>Data we collect/may collect
                        <ol>
                            <li>Any information or personal data provided by you when you register or create an account
                                to use our Services, as well as any data entered in forms, questionnaires, or
                                other
                                means while using our Services.
                            </li>
                            <li>Any information or personal data provided by third party services, such as social
                                networks, as stated in p.4 of this Privacy Policy.
                            </li>
                            <li>Any details or data relating to how you use our Services, including but not limited to
                                statistics on usage of our Services, which devices you use to access our
                                Services,
                                data regarding visits on your profile etc.
                            </li>
                            <li>Catalogues of any messages that you send via our Services, including cats.</li>
                            <li>Any correspondence you make with us, with the purpose of using this data for analysis to
                                improve user experience and the quality of our Services in the future.
                            </li>
                            <li>Any interactions or messages you send us via our social network channels.</li>
                            <li>Any information we receive through the use of cookies as described in p.5 of this
                                document.
                            </li>
                        </ol>
                    </li>
                    <li>Agreement to grant access to Personal Data
                        <ol>
                            <li>By accessing and using any Vuzy Live Services you automatically agree to allow us to
                                collect, process, store, and disclose your Personal Data by means as defined
                                throughout
                                this document.
                            </li>
                            <li>Should you disagree with the usage of your Personal Information or Data in one or more
                                ways as outlined in this document, you should cease to use our Services with
                                immediate effect.
                            </li>
                        </ol>
                    </li>
                    <li>Personal Data and social networks
                        <ol>
                            <li>Any Personal Data collected by us via social networks are also subject to the Privacy
                                Policy of such social networks, which you may agree to gain access to our
                                Services.
                            </li>
                            <li>If you allow a social network to create an account or register with our Services, you
                                also grant us the right to use your email address and any other Personal Data
                                disclosed to us
                                by the relevant social network for both your personal identification and the
                                auto-filling of your profile on Vuzy Live.
                            </li>
                            <li>By using a social network to create a profile or register an account on Vuzy Live, you
                                also provide us with permission to access your contacts on the relevant social
                                network
                                with the purpose of helping them discover our Services.
                            </li>
                            <li>Users who do not have active sessions with a social network agree to allow us to collect
                                your Personal Data through social network widgets, such as the "Like" button,
                                in
                                both cases
                                when you do not have an account with these social networks or have never interacted with
                                them previously.
                            </li>
                            <li>You may alter or change the data that is disclosed to us by social networks or stop
                                interactions with our Services by social networks at any time by changing your
                                settings
                                on the third party provider.
                                However, in these cases we cannot guarantee that you will retain full access to our
                                Services.
                            </li>
                        </ol>
                    </li>
                    <li>Cookies
                        <p>Cookies are small files that store certain information on your computer or mobile
                            devices.</p>
                        <ol>
                            <li>We reserve the right to use cookies or similar technologies to locally store some forms
                                of information each time you make use of our Services. This includes session
                                cookies
                                which are used to authenticate and support user sessions, allowing users to avoid
                                re-entering login and password information or re-authenticating social networks each
                                time
                                they navigate to a new page or section within our Services.
                            </li>
                            <li>The use of cookies allows our Services to integrate with social networks and other
                                platforms provided by third parties. Social network widgets, such as the "Like"
                                button,
                                may also create their own cookie files for users when they don’t have active sessions in
                                these social networks or if they haven’t interacted with the relevant social
                                network previously in any way.
                            </li>
                            <li>Cookies are used to facilitate in-app payments and provide payment instructions on both
                                the Mobile Application and Website. These cookies may be created by third
                                parties
                                such as payment systems and aggregators.
                            </li>
                            <li>Cookies are used to provide users with personalized services such as the display of our
                                Mobile Application and/or Website in a language selected by the user.
                            </li>
                            <li>Cookies allow Vuzy Live to obtain data which helps us better understand how users are
                                interacting with our Services. This data may be used to help us improve our Services
                                in
                                the future. In these cases, cookies may be created by a third party which collects,
                                analyses, and stores information such as Google Analytics.
                            </li>
                        </ol>
                    </li>
                    <li>Payment information
                        <ol>
                            <li>When you acquire any paid service available on our Mobile Application or through in-app
                                purchases, such as in the Apple App Store, we do not store or collect any of the
                                payment information used.
                            </li>
                            <li>When making a purchase on our Mobile Application, you agree that payment systems and
                                aggregators may collect your Personal Information and create cookie files for you
                                as
                                described throughout this document.
                            </li>
                        </ol>
                    </li>
                    <li>Using your Personal Data
                        <p>By using our Services, you agree that we, as well as any provider or company acting in our
                            name and according to our directions only, can use your Personal Data with the
                            following purposes:</p>
                        <ol>
                            <li>To contact you for the purposes of information about our Services, such as providing
                                user support and/or client service, as well as communication regarding information
                                about new services and/or products.
                            </li>
                            <li>To manage your account and relationship with us with the aim of improving your personal
                                experience within our Services.
                            </li>
                            <li>For the purpose of opinion research, user polls, and other marketing interactions,
                                including via email, to gain feedback on your experience within our Services.
                            </li>
                            <li>For the purpose of analysis, the creation of statistical reports, and other actions
                                necessary for tracking potential issues and/or trends that relate to our Services.
                            </li>
                            <li>For the prevention of crime or fraud. In some cases, where legislation dictates, it may
                                become necessary to provide and/or forward your Personal Data to the respective
                                authorities to aide
                                them in the prevention of crimes and other illegal actions.
                            </li>
                            <li>For detecting inappropriate content</li>
                            <li>For all and any other purposes listed in this Privacy Policy or according to national or
                                local legislation.
                            </li>
                        </ol>
                    </li>
                    <li>In certain instances, we may share your personal information with third-party service providers
                        to further improve and optimize our service for you. We may share information
                        about
                        you in the following ways:
                        <ol>
                            <li>With other users. We may share the following information with other users such as your
                                username and name
                            </li>
                            <li>With third parties, we may share your information with the following third parties. With
                                service providers, sellers, and partners. We may share information about you
                                with
                                service providers who perform services on our behalf,
                                sellers that provide goods through our services, and business partners that provide
                                services and functionality.
                            </li>
                            <li>We may share information for advertisement networks for personalized ads</li>
                        </ol>
                        <p>You expressly agree that information provided when you register the App and/or the content
                            your created when using the App (including without limitation, photos, text,
                            messages,
                            posts, html, URLs, pictures, video and other content),
                            may be recorded, stored, published and made available to the rest of Vuzy Live users.
                            Furthermore, other users may follow you on the App, and your followers can see, for
                            example,
                            the user contributed content that you have uploaded to the App. Individuals reading this
                            information may use it or disclose it to other individuals or entities without our
                            control and without your knowledge. We therefore urge you to think carefully about including
                            any specific information you may deem private in the information you provided
                            or
                            content that you create in the App.

                            Your information may also be stored and process in any country in which we provide service
                            to due to server facilities and third-party service teams. By using our service,
                            you
                            consent to any such transfer of information outside of your country.

                            If you do not agree with all of the terms of this Privacy Policy, please do not use our
                            service. Community safety is our number one priority and some of the above user
                            information are mandatory in order to create a safe community for all users.</p>
                    </li>
                    <li>Your profile:
                        <ol>
                            <li>By using our Services, you automatically obtain a personal profile.</li>
                            <li>You may choose to add any information you desire to your profile, including your
                                Personal Data.
                            </li>
                            <li>Other users of our Services may have access to any information you display on your
                                profile.
                            </li>
                        </ol>
                    </li>
                    <li>Terms of storage of your Personal Data
                        <ol>
                            <li>We store your Personal data for the length of time required to achieve the intended
                                purposes for which
                                it was collected.
                            </li>
                            <li>We maintain the right to store your Personal Data after you cease to use our Services
                                and/or after you
                                close an account with Vuzy Live, if the storage of such data is reasonably necessary to
                                fulfil our legal
                                obligations, resolve disputes, prevent illegal actions, prevent violation of third party
                                copyrights,
                                meet legislation and law requirements, and any other purpose as described in this
                                Privacy Policy or any
                                other agreement made with Vuzy Live.
                            </li>
                            <li>Chat messages sent while using our Services may be stored for an infinite amount of time
                                for the purpose
                                of other users having the ability to view the history of their messaging.
                            </li>
                        </ol>
                    </li>
                    <li>Your Personal Data protection
                        <ol>
                            <li>We employ proper technical and physical measures to protect the Personal Data of our
                                users from loss,
                                destruction, change, unauthorized access, disclosure, or any other illegal forms of
                                processing.
                            </li>
                            <li>We employ a modern means of protection for the Personal Data of all users according to
                                the current state
                                of the industry.
                            </li>
                            <li>Due to unforeseen dangers on the Internet, we cannot fully guarantee that all Personal
                                Data will not be
                                subject to unauthorized access, be destroyed, or altered.
                            </li>
                        </ol>
                    </li>
                    <li>External URLs
                        <ol>
                            <li>We reserve the right to place external links to websites and third party goods
                                throughout our
                                Services.
                            </li>
                            <li>We attempt to always provide links and URLs to respected websites, however, we do not
                                accept
                                responsibility for any contents, security, or actions taken on such websites.
                            </li>
                            <li>When you navigate to any third party website, any Personal Data that you provide is
                                managed exclusively
                                by the Privacy Policy of that particular website and we do not assume any responsibility
                                for the Data
                                you decide to provide.
                            </li>
                        </ol>
                    </li>
                    <li>Your rights regarding your Personal Data
                        <p>You have certain rights around the use of your data, including the ability to opt out of
                            promotional emails, cookies, and collection of your data by certain analytics providers.
                            You can update or terminate your account from within our Services, and can also contact us
                            for individual rights requests about your personal data. We want you to be in control of
                            your
                            information, so we have provided you with the following tools:</p>
                        <p></p>
                        <ul>
                            <li><strong>Access / Update tools in the service:</strong> Tools and account settings that
                                help you to access, rectify or delete information that you provided to us and that's
                                associated with your
                                account directly within the service.
                            </li>
                            <li><strong>Device permissions:</strong> Mobile platforms have permission systems for
                                specific types of device data and notifications, such as phone book and location
                                services as well as push
                                notifications.
                                You can change your settings on your device to either consent or oppose the collection
                                of the corresponding information or the display of the corresponding notifications. Of
                                course, if you do
                                that, certain services may lose full functionality.
                            </li>
                            <li><strong>Deletion:</strong> You may correct, change, or delete your Personal Data, or
                                request us to do so on your behalf. For iOS users Personal Data will be automatically
                                deleted when user agreed to delete profile through app. Android users could contact to
                                contact@peanomobile.com to request data deletion. In both cases users could contact
                                provided email to request Personal Data deletion.
                                Any Personal Data transferred to our Services using third party means such as social
                                networks cannot be changed or deleted by us and are fully controlled by such social
                                networks or platforms.
                            </li>
                        </ul>
                        <p>We want you to be aware of your privacy rights. Here are a few key points to remember:</p>
                        <ul>
                            <li><strong>Reviewing your information:</strong> Applicable privacy laws may give you the
                                right to review the personal information we keep about you (depending on the
                                jurisdiction, this may be
                                called right of access,
                                right of portability or variations of those terms).
                            </li>
                            <li><strong>Updating your information:</strong> If you believe that the information we hold
                                about you is inaccurate or that we are no longer entitled to use it and want to request
                                its
                                rectification,
                                deletion or object to its processing, please contact us through our app via contact
                                from.
                            </li>
                        </ul>
                        <p>For your protection and the protection of all of our users, we may ask you to provide proof
                            of identity before we can answer the above requests.</p>
                        <p>Keep in mind, we may reject requests for certain reasons, including if the request is
                            unlawful or if it may infringe on trade secrets or intellectual property or the privacy of
                            another user.
                            If you wish to receive information relating to another user, such as a copy of any messages
                            you received from him or her through our service, the other user will have to contact our
                            Privacy
                            Officer to provide their written consent before the information is released.</p>
                        <p>Also, we may not be able to accommodate certain requests to object to the processing of
                            personal information, notably where such requests would not allow us to provide our service
                            to you anymore.
                            For instance, we cannot provide our service if we do not have your date of birth.</p>
                        <ul>
                            <li><strong>Uninstall:</strong> You can stop all information collection by an app by
                                uninstalling it using the standard uninstall process for your device. If you uninstall
                                the app
                                from your mobile device, the unique identifier associated with your device will continue
                                to be stored. If you re-install the application on the same mobile device, we will be
                                able to
                                re-associate this identifier to your previous transactions and activities.
                            </li>
                            <li><strong>Accountability:</strong>In certain countries, including in the European Union,
                                you have a right to lodge a complaint with the appropriate data protection authority
                                if you have concerns about how we process your personal information. The data protection
                                authority you can lodge a complaint with notably may be that of your habitual residence,
                                where you work
                                or where we are established.
                            </li>
                        </ul>
                    </li>
                    <li>Miscellaneous
                        <ol type="a">
                            <li>To receive more detail on information about how your Personal Data is used, or if you
                                have any further
                                questions or claims, please contact us via the appropriate means.
                            </li>
                            <li>You maintain the right to make a claim to our local data protection authorities in the
                                case of any
                                issues.
                            </li>
                            <li>If a court of law or other competent authority decides that any of the terms of this
                                Privacy Policy is
                                illegal or unenforceable, this does not affect the other terms of this Privacy Policy.
                            </li>
                            <li>We are continually developing and improving our Services which may lead to changes in
                                this Privacy
                                Policy from time to time. On mobile devices, you have the ability to manually manage
                                application updates
                                and therefore may not have the latest version of our application installed. This means
                                you will also not
                                have the latest information regarding our Terms and Privacy Policy. We highly recommend
                                you regularly
                                check for the latest Terms and Privacy Policy.
                            </li>
                            <li>We will attempt to notify you on any essentials changes to our Privacy Policy, however
                                it is your
                                responsibility to ensure you have read the latest version. If you disagree with any
                                terms in this
                                Privacy Policy, you should cease using our Services immediately.
                            </li>
                            <li>Date of last update: 1 March 2023</li>
                        </ol>
                    </li>
                </ol>
            </Container>
        )
    }
}

export default Privacy;
